<script
    src="../../../../../../../AppData/Local/Temp/Rar$DRa14036.14120/src/app/helper/alertTimer/alert-timer.service.ts"
>

</script>
<template>
  <!-- The Modal -->
  <div
      id="editGeneralNotificationModal"
      class="modal"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <button
              type="button"
              class="close"
              data-dismiss="modal"
          >
            &times;
          </button>
        </div>
        <!-- Modal body -->
        <div class="">
          <!--Start-->
          <b-card-code
              title="Create Notification"
          >
            <!-- form -->
            <validation-observer ref="simpleRules">
              <b-form>
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <label for="title">Title</label>
                      <validation-provider
                          #default="{ errors }"
                          name="title"
                          rules="required"
                      >
                        <b-form-input
                            id="title"
                            v-model="createdNotification.data.title"
                            name="title"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <label for="body">Body</label>
                      <validation-provider
                          #default="{ errors }"
                          name="body"
                          rules="required"
                      >
                        <b-form-textarea
                            id="body"
                            v-model="createdNotification.data.body"
                            placeholder=""
                            rows="5"
                            trim
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <label for="type">Notification Type</label>
                      <validation-provider
                          #default="{ errors }"
                          name="type"
                          rules="required"
                      >
                        <v-select
                            v-model="notification.type"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="notificationTypes"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                      v-if="bool === true"
                      md="12"
                  >
                    <b-form-group>
                      <label for="users">Send to</label>
                      <validation-provider
                          #default="{ errors }"
                          name="clients"
                          rules="required"
                      >
                        <v-select
                            v-model="notification.ids"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="clients"
                            :reduce="email => email.id"
                            label="email"
                            multiple
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Modal footer -->
                <div class="modal-footer">
                  <button
                      type="button"
                      class="btn btn-primary"
                      @click.prevent="validationForm"
                  >
                    Submit
                  </button>
                </div>
              </b-form>
            </validation-observer>

            <template #code>
              {{ codeMultiple }}
            </template>
          </b-card-code>
          <!--End-->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BFormTextarea,

} from 'bootstrap-vue'
import {
  required, email, confirmed, password,
} from '@validations'
import vSelect from 'vue-select'
import vue2Dropzone from 'vue2-dropzone'
import { codeMultiple, codeBasic } from './code'
import FormTextAreaDefault from '../../../shared/form-textarea/FormTextAreaDefault.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import notificationsService from '@/services/notificationService'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Spinner from '@/shared/loader/Spinner'

export default {
  components: {
    BCardCode,
    Spinner,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormTextarea,
    FormTextAreaDefault,
    vSelect,
    vueDropzone: vue2Dropzone,
  },
  props: [],
  data() {
    return {
      bool: false,
      notificationTypes: [],
      clients: [],
      createdNotification: {
        data: {
          title: '',
          body: ''
        },
        notification: {
          id: '',
          type: '',
          ids: []
        }
      },
      notification: {
        type: '',
        ids: []
      },
      countries: [],
      createdCity: {
        name: '',
        country_id: ''
      },
      PasswordValue: '',
      passwordCon: '',
      required,
      password,
      email,
      confirmed,
      codeMultiple,
      codeBasic,
      selected: '',
    }
  },
  mounted() {
    this.getCountries()
    this.notificationTypes = ['Broadcast', 'Specific']
    this.$watch(() => this.notification.type, val => {
      if (val === 'Specific') {
        this.bool = true
        this.getClients()
      } else {
        this.bool = false
      }
    })
    this.$watch(() => this.id, id => {
      this.notification.id = id
    })
  },
  methods: {
    getClients() {
      clientsService.index('Individual')
          .then(response => {
            this.clients = response.data.data
          })
          .catch(error => {
          })
    },
    getCountries() {
      this.loading = true
      countriesService.index()
          .then(response => {
            this.countries = response.data.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
          })
    },
    validationForm() {
      this.$refs.simpleRules.validate()
          .then(success => {
            if (success) {
              this.createdNotification.notification.type = this.notification.type
              if(this.notification.type === 'Specific')
                this.createdNotification.notification.ids = this.notification.ids

              this.createdNotification.data = JSON.stringify(this.createdNotification.data)
              console.log(this.createdNotification)
              console.log(this.notification)
              this.$refs.simpleRules.reset();
              notificationsService.post(this.createdNotification)
                  .then(response => {
                    if (response && response.status === 200) {
                      this.$refs.simpleRules.reset();
                      this.$emit('refresh', 'true')

                      this.createdNotification = {
                        data: {
                          title: '',
                          body: ''
                        },
                        notification: {
                          type: '',
                          ids: []
                        },
                      }
                      this.notification = {
                        type : '',
                        ids: []
                      }
                      $('#createGeneralNotificationModal').modal('hide')

                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Success',
                          icon: 'CheckIcon',
                          variant: 'success',
                          text: `General notification has been created successfully !`,
                        },
                      })
                    }

                  })
              // notificationsService.post(this.createdNotification)
              //     .then(response => {
              //       if (response && response.status === 200) {
              //         this.$emit('refresh', 'true')
              //           $('#createGeneralNotificationModal').modal('hide')
              //         this.createdNotification={data: { title: '', body: ''}}
              //         this.$toast({
              //           component: ToastificationContent,
              //           position: 'top-right',
              //           props: {
              //             title: 'Success',
              //             icon: 'CheckIcon',
              //             variant: 'success',
              //             text: `General ntification has been created successfully !`,
              //           },
              //         })
              //       }
              //
              //     })
            }
          })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

label {
  font-weight: bold;
}
</style>
