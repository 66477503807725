import AbstractService from '@/services/abstractService'

class NotificationService extends AbstractService {
  endpoint = 'general-notifications'

  send(notificationId) {
    // define append form
    const formData = new FormData()
    formData.append('id', notificationId)

    return this.http.post('notifications/send', formData)
  }
}

const Service = new NotificationService()

export default Service
