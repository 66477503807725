var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal",attrs:{"id":"createGeneralNotificationModal"}},[_c('div',{staticClass:"modal-dialog modal-lg"},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_c('div',{},[_c('b-card-code',{attrs:{"title":"Create Notification"},scopedSlots:_vm._u([{key:"code",fn:function(){return [_vm._v(" "+_vm._s(_vm.codeMultiple)+" ")]},proxy:true}])},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"title"}},[_vm._v("Title")]),_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","name":"title","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.createdNotification.data.title),callback:function ($$v) {_vm.$set(_vm.createdNotification.data, "title", $$v)},expression:"createdNotification.data.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"body"}},[_vm._v("Body")]),_c('validation-provider',{attrs:{"name":"body","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"body","placeholder":"","rows":"5","trim":""},model:{value:(_vm.createdNotification.data.body),callback:function ($$v) {_vm.$set(_vm.createdNotification.data, "body", $$v)},expression:"createdNotification.data.body"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"type"}},[_vm._v("Notification Type")]),_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.notificationTypes},model:{value:(_vm.createdNotification.type),callback:function ($$v) {_vm.$set(_vm.createdNotification, "type", $$v)},expression:"createdNotification.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.createdNotification.type === 'Link')?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"url"}},[_vm._v("URl")]),_c('validation-provider',{attrs:{"name":"title","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"url","name":"url","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.createdNotification.url),callback:function ($$v) {_vm.$set(_vm.createdNotification, "url", $$v)},expression:"createdNotification.url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1099660785)})],1)],1):_vm._e()],1),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")])])],1)],1)],1)],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-header"},[_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" × ")])])}]

export { render, staticRenderFns }