<template>
  <div>

    <Spinner v-if="loading === true" />

    <div
      v-if="loading === false"
      class="row"
    >
      <div class="col-md-6" />
      <div class="col-md-6 create-button">
        <button
          type="button"
          class="btn btn-primary mb-2 ml-1"
          data-toggle="modal"
          data-target="#createGeneralNotificationModal"
          data-backdrop="static"
          data-keyboard="false"
        >
          Create Notification
          <!--            {{$store.state.appConfig.layout.queen}}-->
        </button>
      </div>
    </div>
    <basic-table
      v-if="loading === false"
      ref="table"
      :columns="columns"
      :values="rows"
      :actions-obj="actionsArray"
      :editable-page="false"
      :deletable-page="false"
      @dynamicAction="handleActions($event)"
      @choosenObject="getEditedObject($event)"
      @deleteItems="removeItems($event)"
      @deletedMultipleIds="catchIds($event)"
    />
    <create
      v-if="$router.currentRoute.name === 'general_notifications'"
      @refresh="renderTable()"
    />

  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import Spinner from '@/shared/loader/Spinner.vue'
import BasicTable from '@/shared/tables/BasicTable.vue'
import notificationService from '@/services/notificationService'
import Create from './create.vue'
import Edit from './edit.vue'

export default {
  components: {
    Spinner,
    BRow,
    BCol,
    Create,
    Edit,
    BasicTable,
  },
  data() {
    return {
      loading: false,
      notificationId: '',
      actionsArray: [],
      editedObject: '',
      clientId: '',
      columns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Body',
          field: 'body',
        },
        {
          label: 'Type',
          field: 'type',
        },
        {
          label: 'URL',
          field: 'url',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  created() {
    this.getNotifications()
    this.actionsArray = [{ title: 'Send', icon: 'MailIcon' }]
  },
  methods: {
    getNotifications() {
      this.loading = true
      notificationService.index().then(response => {
        this.rows = response.data.data
        console.log('nottt', this.rows)
        this.loading = false
      }).catch(error => {
        this.loading = false
      })
    },
    handleActions(itemObj) {
      switch (itemObj.key) {
        case 'Send': {
          this.notificationId = itemObj.id
          this.confirmSendGeneralNotificationText(this.notificationId)
        }
          break
        default:
          break
      }
    },
    getEditedObject(obj) {
      this.editedObject = obj
    },
    catchIds(ids) {
      this.deletedArry = ids
    },
    removeItems(arr) {
      this.confirmDeleteText(arr)
    },
    confirmDeleteText(id) {
      if (arr.length > 0) {
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            notificationService.delete(arr.join()).then(response => {
              if (response && response.status === 200) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your Items have been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                }).then(() => {
                  this.renderTable()
                })
              }
            }).catch(error => Promise.reject(error))
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: '',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.loading = false
          } else {
            this.loading = false
          }
        })
      }
    },
    confirmSendGeneralNotificationText(id) {
      if (id) {
        this.$swal({
          title: 'Are you sure of sending this general notification?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, send it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.loading = true

            notificationService.send(id).then(response => {
              if (response && response.status === 200) {
                this.$swal({
                  icon: 'success',
                  title: 'Sent!',
                  text: 'General notification has been sent.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                }).then(() => {
                  this.renderTable()
                  this.loading = false
                })
              }
            }).catch(error => Promise.reject(error))
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: '',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.loading = false
          } else {
            this.loading = false
          }
        })
      }
    },

    renderTable() {
      this.getNotifications()
    },

  },
}
</script>
<style>
input[type="checkbox"]:checked + label::after {
  content: '';
  position: absolute;
  width: 1.2ex;
  height: 0.4ex;
  background: rgba(0, 0, 0, 0)!important;
  top: 0.9ex;
  left: 0.4ex;
  border: 3px solid blue;
  border-top: none;
  border-right: none;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

input[type="checkbox"] {
  line-height: 2.1ex;
}

</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

</style>
